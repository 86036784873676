<template>
  <v-container>
    <v-alert type="success" v-if="donationSuccessful" icon=" ">{{
      $t("profile.menu.donate.successMessage")
    }}</v-alert>
    <v-card>
      <v-card-title
        class="py-9 text-uppercase white--text bck-image-donate"
        v-html="$t('profile.menu.donate.title')"
      ></v-card-title>
      <v-row>
        <v-col cols="12" md="10"
          ><v-card-subtitle class="px-5 pt-9 font-weight-bold">{{
            $t("profile.menu.donate.subtitle")
          }}</v-card-subtitle></v-col
        >
        <v-col cols="12" md="10">
          <v-card-text>
            {{
              $t("profile.menu.donate.text", {
                userEmail: user.email
              })
            }}
          </v-card-text>
        </v-col>
        <v-col cols="12" md="10">
          <v-form class="px-5" v-model="isValid" ref="form" @submit.prevent>
            <v-row align="center">
              <!-- CARTA FEDELTA NUMBER -->
              <v-col cols="12" md="5">
                <label>{{ $t("profile.menu.donate.form.cardNumber") }}</label>
                <v-text-field
                  hide-spin-buttons
                  type="number"
                  v-model="formData.to_fidelity_card"
                  :rules="[requiredRules(), validateCardNumber]"
                  :hint="$t('profile.menu.donate.form.cardNumberHint')"
                  required
                  outlined
                  dense
                  class="mt-2"
                ></v-text-field></v-col
              ><!-- CONFIRM CARTA FEDELTA NUMBER -->
              <v-col cols="12" md="5">
                <label>{{
                  $t("profile.menu.donate.form.confirmCardNumber")
                }}</label>
                <v-text-field
                  v-model="fideltyCardNumberConfirm"
                  :rules="cartaFedeltaConfirmRules"
                  hide-spin-buttons
                  required
                  outlined
                  dense
                  class="mt-2"
                ></v-text-field
              ></v-col>
              <!-- DONATION POINTS -->
              <v-col cols="12" md="5">
                <label>{{
                  $t("profile.menu.donate.form.donationPoints")
                }}</label>
                <v-text-field
                  v-model="formData.transfer_points"
                  :rules="[requiredRules()]"
                  required
                  outlined
                  dense
                  class="mt-2"
                ></v-text-field
              ></v-col>
              <!-- USER NAME -->
              <v-col cols="12" md="5">
                <label>{{ $t("profile.menu.donate.form.name") }}</label>
                <v-text-field
                  v-model="formData.userName"
                  outlined
                  dense
                  class="mt-2"
                ></v-text-field
              ></v-col>
              <!-- MESSAGGE -->
              <v-col cols="12" md="10">
                <label>{{ $t("profile.menu.donate.form.message") }}</label>
                <v-textarea
                  v-model="formData.receiving_message"
                  outlined
                  dense
                  class="mt-2"
                  :hint="$t('profile.menu.donate.form.messageHint')"
                ></v-textarea
              ></v-col>
              <v-col cols="12" md="4">
                <v-btn
                  :loading="loading"
                  @click="donatePoints"
                  large
                  depressed
                  color="primary"
                  class="w-100 text-uppercase"
                  >{{ $t("profile.menu.donate.form.cta") }}</v-btn
                >
              </v-col></v-row
            ></v-form
          >
        </v-col>
      </v-row>
    </v-card></v-container
  >
</template>
<style scoped lang="scss"></style>
<script>
import { requiredValue } from "~/validator/validationRules";
import { mapGetters } from "vuex";
import customService from "@/service/customService";

export default {
  name: "Donate",
  data() {
    return {
      isValid: false,
      loading: false,
      formData: {
        to_fidelity_card: null,
        transfer_points: null,
        to_email_info: "dev@digitelematica.com",
        userName: null,
        receiving_message: null
      },
      donationSuccessful: false,
      fideltyCardNumberConfirm: null,
      requiredRules: requiredValue,
      cartaFedeltaConfirmRules: [
        v => !!v || "Campo Obbligatoria",
        v =>
          v === this.formData.to_fidelity_card ||
          "il numero della carta non coincidono"
      ]
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },

  methods: {
    validateCardNumber(value) {
      const validateCard = /^0\d{12}$/;
      return (
        validateCard.test(value) ||
        "Numero della carta deve essere minimo 13 cifre compreso lo 0 iniziale"
      );
    },
    async donatePoints() {
      let _this = this;
      try {
        if (_this.$refs.form.validate()) {
          _this.loading = true;
          let result = await customService.validDonationPoints(this.formData);
          if (result && result.response && result.response.status == 0) {
            let finalResult = await customService
              .donatePoints(this.formData)
              .then(function(response) {
                if (response.response.status === 0) {
                  _this.donationSuccessful = true;
                  _this.loading = false;
                  setTimeout(function() {
                    _this.$refs.form.reset();
                  }, 500);
                } else {
                  let error = response.response.errors[0].error;
                  global.EventBus.$emit("error", {
                    message: error
                  });
                  _this.$refs.form.reset();
                  _this.loading = false;
                }
              });
            console.log(finalResult);
          } else {
            _this.loading = false;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  },
  mounted() {
    this.formData.userName = this.user.firstName || null;
  }
};
</script>
